const loadDisqus = require('client/disqus').loadDisqus;
const loadDisqusComments = require('client/disqus').loadDisqusComments;
const loadDisqusIfVisible = require('client/disqus').loadDisqusIfVisible;

let requestAnimationFrameId;

let DEBUG = false;
function log() {
  if (DEBUG) {
    console.log.apply(console, arguments);
  }
}

let TABLET_WIDTH = 840;

// don't handle onscroll more often than animation
function onWindowScrollAndResizeThrottled() {
  log('onWindowScrollAndResizeThrottled', requestAnimationFrameId);
  if (requestAnimationFrameId) return;

  requestAnimationFrameId = window.requestAnimationFrame(function() {
    onWindowScrollAndResize();
    requestAnimationFrameId = null;
  });
}

window.addEventListener('resize-internal', onWindowScrollAndResizeThrottled);
window.addEventListener('scroll', onWindowScrollAndResizeThrottled);
window.addEventListener('resize', onWindowScrollAndResizeThrottled);

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
} else {
  onDOMContentLoaded();
}

function onDOMContentLoaded() {
  initThemeToggler();

  if (window.location.hash.match(/#comment-/)) {
    loadDisqus();
  }
  loadDisqusComments();

  onWindowScrollAndResizeThrottled();
  if (LANG === 'zh') {
    document.addEventListener('click', (event) => {
      let link = event.target;
      let wechatqq = link.closest('.wechat-qq');

      if (!wechatqq) return;

      event.preventDefault();

      let modal = new Modal();
      modal.setContent(`<div id='wechat-qr-modal'>
        <img src='/qr-wechat.png' alt='WeChat'>
        <img src='/qr-qq.png' alt='QQ'>
      </div>`);
    });
  }
}

function compactifySidebar() {
  log('compactifySidebar');
  let sidebar = document.querySelector('.sidebar');

  if (!sidebar.observer) {
    // watch for ads
    let observer = (sidebar.observer = new MutationObserver(
      (mutationRecords) => {
        // console.log(mutationRecords);
        for (let record of mutationRecords) {
          if (record.type !== 'childList') {
            return;
          }
          // console.log('resize');
          onWindowScrollAndResizeThrottled();
        }
      }
    ));
    observer.observe(sidebar, {
      childList: true,
      subtree: true,
    });
  }

  let sidebarContent = sidebar.querySelector('.sidebar__content');
  let sidebarInner = sidebar.querySelector('.sidebar__inner');

  let hasStickyFooter = sidebar.classList.contains('sidebar_sticky-footer');
  let isCompact = sidebar.classList.contains('sidebar_compact');

  if (isCompact) {
    let emptySpaceSize;
    if (hasStickyFooter) {
      emptySpaceSize =
        sidebarContent.lastElementChild.getBoundingClientRect().top -
        sidebarContent.lastElementChild.previousElementSibling.getBoundingClientRect()
          .bottom;
    } else {
      emptySpaceSize =
        sidebarContent.getBoundingClientRect().bottom -
        sidebarContent.lastElementChild.getBoundingClientRect().bottom;
    }

    log('decompact?', emptySpaceSize);

    // enough space to occupy the full height in decompacted form without scrollbar
    if (emptySpaceSize > 150) {
      sidebar.classList.remove('sidebar_compact');
    }
  } else {
    log(sidebarInner.scrollHeight, sidebarInner.clientHeight);
    if (sidebarInner.scrollHeight > sidebarInner.clientHeight) {
      log('compact!');
      sidebar.classList.add('sidebar_compact');
    }
  }
}

function onWindowScrollAndResize() {
  let toolbarSelector = '.sitetoolbar';
  let sitetoolbar = document.querySelector(toolbarSelector);

  if (!sitetoolbar) {
    log('no sitetoolbar');
    return; // page in a no-top-nav layout
  }

  let sidebar = document.querySelector('.sidebar');

  if (sidebar) {
    const page = document.querySelector('.page');
    const sidebarTop =
      // checking if it is a profile page and window has tablet size
      page.classList.contains('page_profile') &&
      getComputedStyle(page).display === 'flex'
        ? 0
        : Math.max(sitetoolbar.getBoundingClientRect().bottom, 0) + 'px';
    sidebar.style.top = sidebarTop;
    compactifySidebar();
  }

  setUserScaleIfTablet();

  loadDisqus();
}

function setUserScaleIfTablet() {
  let isTablet = document.documentElement.clientWidth <= TABLET_WIDTH;
  let content = document.querySelector('meta[name="viewport"]').content;
  content = content.replace(
    /user-scalable=\w+/,
    'user-scalable=' + (isTablet ? 'yes' : 'no')
  );
  document.querySelector('meta[name="viewport"]').content = content;
}

function initThemeToggler() {
  let themeTogglers = [...document.querySelectorAll('input[data-theme-changer]')];
  for(let themeToggler of themeTogglers) {
    themeToggler.onchange = () => {
      const newThemeIsDark = themeToggler.checked;
      const oldTheme = newThemeIsDark ? 'light' : 'dark';
      const newTheme = newThemeIsDark ? 'dark' : 'light';
      changeTheme(oldTheme, newTheme);
    };

    themeToggler.checked = document.documentElement.dataset.theme == 'dark';
  }
}

function changeTheme(oldTheme, newTheme) {
  // console.info("Theme change", oldTheme, newTheme);

  document.documentElement.dataset.theme = newTheme;
  localStorage.setItem('theme', newTheme);

  for(let elem of document.querySelectorAll('[data-use-theme]')) {
    if (elem.tagName == 'OBJECT') {
      window.themeSvg(elem, oldTheme, newTheme);
    }
  }

  if (window.DISQUS) {
    DISQUS.reset({ reload: true, config: disqus_config });
  }
}
